<template>
  <div>
    <div class="pt-3 d-flex justify-space-between align-end" v-if="data">
      <div>
        <div class="pb-3">
          {{ Moment(data.created_at).format("YYYY-MM-DD HH:mm") }} --
          <span :class="statusType">{{
            $t(`order.status.${data.status}`)
          }}</span>
        </div>
        <div class="pb-3">訂單編號: {{ data.order_no }}</div>
        <div class="pb-3">
          <paymentStatus
            :ref="'paymentStatus'"
            :orderData="data"
            :catchPaymentAllStatus="catchPaymentAllStatus"
          ></paymentStatus>
        </div>
      </div>

      <div v-if="currMemberRole._isManager" class="pb-5">
        <v-dialog v-model="dialogPaymentTypList" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="
                (!isCashOnDelivery || !data.payment_type) &&
                  paymentAllStatus.isUnpaid != null &&
                  paymentAllStatus.isUnpaid
              "
              class="mb-2"
              block
              depressed
              dark
              color="primary"
              outlined
              v-bind="attrs"
              v-on="on"
            >
              付款
            </v-btn>
          </template>
          <paymentInterface
            ref="paymentInterface"
            :layout="1"
            :data="data"
            :beforePay="updatePaymentType"
          ></paymentInterface>
        </v-dialog>

        <v-btn
          depressed
          dark
          color="primary"
          :to="{
            name: 'store-cooperation-info',
            params: { cooperateProviderId: providerId },
          }"
        >
          供應商資訊
        </v-btn>
      </div>
    </div>

    <div
      class="d-flex justify-end align-end"
      v-if="
        false && data && data.adjustmentData && data.adjustmentData.length > 0
      "
    >
      <v-btn
        depressed
        dark
        color="primary"
        @click="openOrderAdjustmentRecordDialog = true"
      >
        查看訂單調整
      </v-btn>
    </div>

    <order-adjustment-record
      :openOrderAdjustmentRecordDialog="openOrderAdjustmentRecordDialog"
      :orderData="data || {}"
      :adjustmentData="(data && data.adjustmentData) || []"
      :providerId="providerId"
      :storeId="storeId"
      @updateOrderAdjustmentRecordDialogSwitch="
        (isOpen) => (openOrderAdjustmentRecordDialog = isOpen)
      "
    />
  </div>
</template>

<script lang="babel" type="text/babel">
import Moment from "moment";
import qs from "qs";

import ItemMixin from "@/components/form/custom/ItemMixin.js";

import orderConstants from "@/modules/base/config/orderConstants";
import OrderAdjustmentRecord from "@/modules/store/views/orderDetail/OrderAdjustmentRecord";

import paymentTypeForB2C from "@/components/payment/mixins/paymentTypeForB2C.js";
import paymentInterface from "@/components/payment/payment.vue";
import paymentStatus from "@/components/payment/paymentStatus.vue";

import { mapGetters } from "vuex";

export default {
  mixins: [ItemMixin, paymentTypeForB2C],
  components: {
    OrderAdjustmentRecord,
    paymentInterface,
    paymentStatus,
  },
  data: () => ({
    data: null,
    orderConstants: orderConstants,
    Moment: Moment,
    openOrderAdjustmentRecordDialog: false,
    dialogPaymentTypList: false,
    paymentRecord: {},
    paymentAllStatus: {},
  }),
  computed: {
    ...mapGetters("member", ["currMemberRole"]),
    providerId() {
      return this.$route.params.providerId;
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    storeInfo() {
      if (!this.data) return [];
      if (!this.data.store_info) return [];
      const store_info = this.data.store_info;
      return [
        { text: "客戶", value: this.data.store_name },
        { text: "聯絡人", value: store_info.contact },
        { text: "電話", value: store_info.phone },
        {
          text: "地址",
          value: `${store_info.city}${store_info.district}${store_info.address}`,
        },
        { text: "統一編號", value: store_info.uniform_number },
      ];
    },
    statusType() {
      if (!this.data) return "";
      switch (this.data.status) {
        case "finished":
          return "lighten-text";
        case "reject":
        case "canceled":
          return "red--text";
        case "request_return":
          return "dark-text";
        default:
          return "provider-font-color";
      }
    },
    orderId() {
      return this.data ? this.data.order_no : null;
    },
    isCashOnDelivery() {
      return this.cashPaymentTypes.includes(this.data.payment_type);
    },
  },
  watch: {
    propsValue: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.propsValue) return;
        this.data = this.$eagleLodash.cloneDeep(this.propsValue);
      },
    },
    data: {
      deep: true,
      handler() {},
    },
  },
  methods: {
    async checkIsPaymentJumpsBack() {
      this.$store.dispatch("loading/active");
      const query = this.$route.query;

      try {
        const psp = query.psp;
        const orderId = query.orderId;

        if (psp == "linepay") {
          const params = {
            orderId: orderId,
          };

          const paymentCheckRes = await this.$api.collection.orderApi.paymentConformByLinePay(
            params
          );

          if (paymentCheckRes.status == "success") {
            this.$snotify.success("付款成功");
          } else {
            this.$snotify.error("付款失敗或已完成付款，請確認訂單明細。");
          }
        } else if (psp == "ecpay") {
          // 直接在訂單詳細頁付款方式顯示付款狀態
        }
        window.history.replaceState({}, "", `${window.location.pathname}`);
      } catch (err) {
        console.error(err);
        this.$snotify.error("確認功能異常");
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async updatePaymentType(params) {
      this.$store.dispatch("loading/active");
      try {
        this.dialogPaymentTypList = false;
        this.data = await this.$api.collection.orderApi.update(
          this.storeId,
          this.providerId,
          this.orderId,
          qs.stringify(params)
        );
      } catch (err) {
        console.error(err);
        this.$snotify.error("付款失敗");
      }
    },
    catchPaymentAllStatus(data) {
      console.log(data);
      this.paymentAllStatus = data;
    },
  },
  async created() {
    if (this.$route.query.psp) this.checkIsPaymentJumpsBack();
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style scoped lang="sass">
.lighten-text
  color: #37D21E

.dark-text
  color: #000000
</style>
