import { render, staticRenderFns } from "./OrderIntro.vue?vue&type=template&id=3f3cf36f&scoped=true&"
import script from "./OrderIntro.vue?vue&type=script&lang=babel&"
export * from "./OrderIntro.vue?vue&type=script&lang=babel&"
import style0 from "./OrderIntro.vue?vue&type=style&index=0&id=3f3cf36f&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f3cf36f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBtn,VDialog})
