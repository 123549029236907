var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data)?_c('div',{staticClass:"pt-3 d-flex justify-space-between align-end"},[_c('div',[_c('div',{staticClass:"pb-3"},[_vm._v(" "+_vm._s(_vm.Moment(_vm.data.created_at).format("YYYY-MM-DD HH:mm"))+" -- "),_c('span',{class:_vm.statusType},[_vm._v(_vm._s(_vm.$t(("order.status." + (_vm.data.status)))))])]),_c('div',{staticClass:"pb-3"},[_vm._v("訂單編號: "+_vm._s(_vm.data.order_no))]),_c('div',{staticClass:"pb-3"},[_c('paymentStatus',{ref:'paymentStatus',attrs:{"orderData":_vm.data,"catchPaymentAllStatus":_vm.catchPaymentAllStatus}})],1)]),(_vm.currMemberRole._isManager)?_c('div',{staticClass:"pb-5"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(
              (!_vm.isCashOnDelivery || !_vm.data.payment_type) &&
                _vm.paymentAllStatus.isUnpaid != null &&
                _vm.paymentAllStatus.isUnpaid
            ),expression:"\n              (!isCashOnDelivery || !data.payment_type) &&\n                paymentAllStatus.isUnpaid != null &&\n                paymentAllStatus.isUnpaid\n            "}],staticClass:"mb-2",attrs:{"block":"","depressed":"","dark":"","color":"primary","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" 付款 ")])]}}],null,false,2705614833),model:{value:(_vm.dialogPaymentTypList),callback:function ($$v) {_vm.dialogPaymentTypList=$$v},expression:"dialogPaymentTypList"}},[_c('paymentInterface',{ref:"paymentInterface",attrs:{"layout":1,"data":_vm.data,"beforePay":_vm.updatePaymentType}})],1),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"primary","to":{
          name: 'store-cooperation-info',
          params: { cooperateProviderId: _vm.providerId },
        }}},[_vm._v(" 供應商資訊 ")])],1):_vm._e()]):_vm._e(),(
      false && _vm.data && _vm.data.adjustmentData && _vm.data.adjustmentData.length > 0
    )?_c('div',{staticClass:"d-flex justify-end align-end"},[_c('v-btn',{attrs:{"depressed":"","dark":"","color":"primary"},on:{"click":function($event){_vm.openOrderAdjustmentRecordDialog = true}}},[_vm._v(" 查看訂單調整 ")])],1):_vm._e(),_c('order-adjustment-record',{attrs:{"openOrderAdjustmentRecordDialog":_vm.openOrderAdjustmentRecordDialog,"orderData":_vm.data || {},"adjustmentData":(_vm.data && _vm.data.adjustmentData) || [],"providerId":_vm.providerId,"storeId":_vm.storeId},on:{"updateOrderAdjustmentRecordDialogSwitch":function (isOpen) { return (_vm.openOrderAdjustmentRecordDialog = isOpen); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }